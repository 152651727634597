import React, { lazy } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import PATH_NAMES from "./constants/pathNames";

const BookingPage = lazy(() => import("./pages/Bookings/BookingPage"));
const BookingsPage = lazy(() => import("./pages/Bookings/BookingsPage"));
const NewBookingPage = lazy(() => import("./pages/Bookings/NewBookingPage"));
const GuestPage = lazy(() => import("./pages/Guests/GuestPage"))
const GuestsPage = lazy(() => import("./pages/Guests/GuestsPage"));
const NewGuestPage = lazy(() => import("./pages/Guests/NewGuestPage"));
const RoomPage = lazy(() => import("./pages/Rooms/RoomPage"))
const RoomsPage = lazy(() => import("./pages/Rooms/RoomsPage"));
const NewRoomPage = lazy(() => import("./pages/Rooms/NewRoomPage"));
const RoomTypePage = lazy(() => import("./pages/RoomTypes/RoomTypePage"));
const RoomTypesPage = lazy(() => import("./pages/RoomTypes/RoomTypesPage"));
const NewRoomTypePage = lazy(() => import("./pages/RoomTypes/NewRoomTypePage"));

const AppRoutes = () => {
    return (
            <Routes>
                <Route path={'*'} element={<Navigate to = {PATH_NAMES.BOOKINGS} replace/>}></Route>
                <Route path={PATH_NAMES.BOOKING} element={<BookingPage/>}></Route>
                <Route path={PATH_NAMES.BOOKINGS} element={<BookingsPage/>}></Route>
                <Route path={PATH_NAMES.NEW_BOOKING} element={<NewBookingPage/>}></Route>
                <Route path={PATH_NAMES.GUEST} element={<GuestPage/>}></Route>
                <Route path={PATH_NAMES.GUESTS} element={<GuestsPage/>}></Route>
                <Route path={PATH_NAMES.NEW_GUEST} element={<NewGuestPage/>}></Route>
                <Route path={PATH_NAMES.ROOM} element={<RoomPage/>}></Route>
                <Route path={PATH_NAMES.ROOMS} element={<RoomsPage/>}></Route>
                <Route path={PATH_NAMES.NEW_ROOM} element={<NewRoomPage/>}></Route>
                <Route path={PATH_NAMES.ROOM_TYPE} element={<RoomTypePage/>}></Route>
                <Route path={PATH_NAMES.ROOM_TYPES} element={<RoomTypesPage/>}></Route>
                <Route path={PATH_NAMES.NEW_ROOM_TYPE} element={<NewRoomTypePage/>}></Route>
            </Routes>
    );
};

export default AppRoutes;