import React, { Suspense } from 'react';
import LayoutSCI from "./components/blocks/Layout/LayoutSCI";
import AppRoutes from "./appRoutes";

function App() {
    return (
            <LayoutSCI>
                <Suspense fallback={<div>Loading...</div>}>
                    <AppRoutes/>
                </Suspense>
            </LayoutSCI>
    );
}

export default App;
